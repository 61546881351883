"use client";
import { Button, PasswordInput, TextInput } from "@mantine/core";
import { signIn } from "next-auth/react";
import { useState } from "react";
import { Lock, Message } from "react-iconly";

const LoginForm = ({ className }: { className: string }) => {
  const [loading, setLoading] = useState(false);
  const onFormSubmit = async (event: any) => {
    event.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData(event.target);
      const email = formData?.get("email");
      const password = formData?.get("password");
      await signIn("credentials", { email, password });
    } catch (error) {
      setLoading(false);
      console.log({ error });
    }
  };
  return (
    <form className={className} onSubmit={onFormSubmit}>
      <TextInput
        leftSection={<Message />}
        disabled={loading}
        required
        name="email"
        type="email"
        placeholder="Email"
        className="gr-login-form-input"
      />

      <PasswordInput
        leftSection={<Lock />}
        height={50}
        disabled={loading}
        required
        name="password"
        type="password"
        placeholder="Password"
        className="gr-login-form-input"
      />

      <Button
        disabled={loading}
        type="submit"
        className="min-w-full lg:min-w-[300px] min-h-[48px] border rounded-lg px-3 outline-none bg-gr-primary-300 text-white font-[600] tracking-tight text-[18px] mt-[5px]"
        loading={loading}
      >
        Login
      </Button>
    </form>
  );
};

export default LoginForm;
